.container{
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right bottom,#21D4FD,#B721FF);
    display: flex;
    align-items: center;
    justify-content: center;
}
.container .login-box{
    width: 25%;
    background: white;
    border-radius: 10px;
    box-shadow: -3px 5px 20px rgba(0, 0, 0,0.21);
    padding: 25px 35px;
    transition: all ease-in 0.3s;
}
.container .login-box .title{
    width: 100%;
    height: 50px;
}
.container .login-box .title h2{
    font-size: 1.8em;
    color:blue;
    font-weight: 650;
}
.container .login-box form{
    width: 100%;
    padding: 20px 0px;
}
.login-box form .form-field{
    width: 100%;
    margin-bottom: 10px;
    transition: all ease-in 0.4s;
}
form .form-field:focus-within label{
    color:#B721FF;
    transition: all ease-in 0.4s;
}
.login-box form .form-field .input-field:focus-within{
    border: 2px solid #B721FF;
}
.login-box form .form-field .input-field:focus-within i{
    color: #B721FF;
}
.login-box form .form-field .input-field{
    width: 100%;    
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    border: 2px solid black;
    border-radius: 4px;
    margin-top: 4px;
    padding: 1.2%;
}
.form-field label{
    font-size: 0.92em;
    font-weight: 590;
}
.form-field .input-field i{
    width: 10%;
    font-size: 1.5em;
    transition: all ease-in 0.4s;
    display: grid;
    place-content: center;
}
.form-field .input-field input,
.form-field .input-field select{
    width: 90%;
    padding: 5px;
    outline: none;
    border: none;
    font-size: 0.95em;
}
.form-field .error{
    margin-top: 5px;
    font-weight: 550;
    font-size: 0.9em;
    color:red;
}
.login-box form button{
    width: 100%;
    margin-top: 20px;
    padding: 12px;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: blue;
    font-size: 1.02em;
    font-weight: 550;
    color:white;
    letter-spacing: 2px;
    transition: all ease-in 0.3s;
}
.login-box form button:active{
    transition: all ease-in 0.3s;
    scale: 0.92;
}
.loading-container{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0,0.31);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}
.loading-container .box{
    padding: 15px 20px;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0,0.21);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.loading-container .box .msg{
    font-size: 0.8em;
    font-weight: 510;
}
.loading-container .box .circle{
    width: 60px;
    height: 60px;
    border: 5px solid #ddd;
    border-radius: 50%;
    border-top: 5px solid red;
    border-bottom: 5px solid blue;
    transition: all ease-in 0.3s;
    animation: rotate linear infinite 1s;
}
@keyframes rotate{
    100%{
        rotate: 360deg;
    }
}
.footer-container{
    position: fixed;
    left:0;
    bottom: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.footer-container .copy-right{
    font-size: 0.92em;
    color:white;
    font-weight: 520;
    font-family: "Afacad Flux";
}
