.header{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 3%;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.21);
}
.header h2{
    font-size: 1.08em;
    font-weight: 500;
}
.header button{
    padding: 10px 20px;
    outline: none;
    background: #fff;
    border-radius: 4px;
    border: 2px solid crimson;
    color:crimson;
    font-weight: 520;
    cursor: pointer;
    transition: all ease-in 0.35s;
}
.header button:hover{
    background: crimson;
    color:white;
}
.admin-footer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: #111;
    display: grid;
    place-content: center;
}
.admin-footer p{
    color:white;
    font-family: 'Afacad Flux';
    font-style: 400;
}
.sidebar{
    width: 200px;
    height: 100vh;
    background: #fff;
    float:left;
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: start;
    justify-content: start;
    gap:10px;
}
.sidebar a{
    text-decoration: none;
    width: 100%;
    padding: 10px 15px;
    border-radius: 8px;
    color:#111;
    font-size: 0.92em;
    transition: all ease-in 0.15s;
}
.sidebar a i{
    margin-right: 8px;
    color:#222;
    font-size: 1.05em;
}
.sidebar .active{
    color:white;
    background: #222;
    font-weight: 530;
}
.sidebar .active i{
    color:white;
}
.sidebar a:hover{
    background: #eee;
    font-weight: 520;
    color:#222;
}
.sidebar a:hover i{
    color:#222;
}
.sidebar button{
    width: 90%;
    padding: 10px;
    background: crimson;
    outline: none;
    border:2px solid crimson;
    cursor: pointer;
    border-radius: 4px;
    color:white;
    font-weight: bold;
    margin-top: 20px;
}
.outlet-container{
    float:right;
    width: calc(100% - 220px);
    height: 92vh;
    background: #dddfff;
    overflow: hidden;    
}
.child-container{
    width: 100%;
    height: 100%;
}
.child-container .action-panel{
    width: 100%;
    height: 8vh;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:20px;
    background-color: #fff;
}
.child-container .action-panel h2{
    font-size: 22px;
    font-weight: 530;
}
.child-container .action-panel i{
    font-size: 22px;
}
.action-panel button{
    padding: 8px 16px;
    outline: none;
    transition: all ease-in 0.35s;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color:#222;
    background: #fff;
    border: 2px solid #222;
}
.action-panel .activeBtn{
    padding: 8px 16px;
    outline: none;
    transition: all ease-in 0.35s;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    border: 2px solid #222;
    color:#fff;
    box-shadow: inset 200px 0px 0px #222;
    font-weight: 530;
}
.child-container .element-container{
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;    
    background: #dddfff;
}
.child-container .form-container{
    width: 100%;
    height: 100%;
    background: #dddfff;
    padding: 20px 30px;
    display: flex;
    align-items: start;
    justify-content: start;
    gap:20px;
}
.child-container .form-container .balance-container{
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap:25px;
    
}
.form-container .balance-container .balance{    
    padding: 20px 30px;
    background: #fff;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.21);
    border-radius: 4px;
}
.balance-container .balance .title-balance{
    width: 350px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:5px;
}
.balance .title-balance h2{
    width: 80%;
    font-size: 18px;
    font-weight: 520;
}
.balance .title-balance img{
    width: 12%;
    height: 25px;
    object-fit: contain;
}
.balance .balance-field{
    width: 100%;
    margin: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.balance .add-field{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
}
.balance .add-field button{
    background: transparent;
    padding:10px 6px;
    outline: none;
    border: none;
    cursor: pointer;
    color:#222;
    font-size: 15px;
    font-weight: 480;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 4px;
    gap:8px;
    transition: all ease-in 0.35s;
}
.balance .add-field .active-btn1{
    background: lightgray;
    padding:10px 6px;
    outline: none;
    border: none;
    cursor: pointer;
    color:#222;
    font-size: 15px;
    font-weight: 480;
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 4px;
    gap:8px;
    transition: all ease-in 0.35s;
}
.balance .add-field button:hover{
    background: lightgray;
}
.balance .balance-field .open{
    width: 45%;
    font-size: 15px;
    font-weight: 500;
    text-align: start;
}
.balance .balance-field .value{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:2px;
}
.balance .balance-field .value img{
    width: 10%;
    height: 13px;
    object-fit: contain;
}
.balance .balance-field .value .close{
    font-size: 15px;
    font-weight: 550;
}
.balance-container .balance .balance-field{
    width: 350px;
    margin-bottom: 12px;
}
.form-container .cash-flow{
    padding: 20px 30px;
    background: #fff;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.21);
    border-radius: 4px;
}
.cash-flow .form-field{
    width: 350px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:5px 20px;
}
.form-container .cash-manager{
    width: calc(100% - 350px);
    max-height: 92%;
    padding: 10px 20px;
    overflow-y: auto;
    background: #fff;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.21);
    border-radius: 4px;
}
.form-container .cash-manager .filter-fields{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:1em;
}
.cash-manager .filter-fields .filter{
    width: 40%;
    padding: 5px 8px;
    background: #ddd;
    border-radius: 5px;
}
.cash-manager .filter-fields .filter:last-child{
    flex: 1;
    background: none;
}
.cash-manager .filter-fields .filter button{
   padding: 10px 20px;
   cursor: pointer;
}
.cash-manager .filter-fields .filter h2{
    font-size: 14px;
    font-weight: 510;
    margin: 5px 0px;
}
.filter-fields .filter .filter-field{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
    margin: 5px 0px;
}
.filter .filter-field input[type='date']{
    width: 45%;
    font-size: 12px;
    padding: 4px;
    border-radius: 3px;
    outline: none;
    border: 1px solid #444;
}
.filter .filter-field select{
    width: 95%;
    font-size: 12px;
    padding: 4px;
    border-radius: 3px;
    outline: none;
    border: 1px solid #444;
}
.filter .filter-field p{
    font-size: 14px;
    font-weight: 520;
}
.form-container .cash-flow p{
    padding: 8px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 510;
    color:red;
}
.cash-flow .form-field label{
    width: 20%;
}
.cash-flow .form-field .value-field{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
    border: 2px solid #ddd;
    border-radius: 4px;
    padding: 4px;
    transition: all ease-in 0.3s;
}
.cash-flow .form-field .value-field:focus-within{
    background: rgba(0, 0, 255, 0.42);
    border: 2px solid blue;
}
.cash-flow .form-field .value-field:focus-within input,
.cash-flow .form-field .value-field:focus-within select{
    font-weight: 520;
    color:white;
}
.cash-flow .form-field .value-field img{
    width: 15px;
    object-fit: contain;
}
.cash-flow .button-field{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.cash-flow button{
    width: 50%;
    margin: 5px 0px;
    outline: none;
    padding: 8px;
    border: none;
    border-radius: 5px;
    font-weight: 480;
    cursor: pointer;
    transition: all ease-in 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.cash-flow .cash-in{
    background: darkgreen;
    color:white;
}
.cash-flow .cash-out{
    background: crimson;
    color:white;
}
.cash-flow .form-field .value-field input,
.cash-flow .form-field .value-field select{
    width: 90%;
    padding: 4px;
    border: none;
    background: transparent;
    outline: none;
}
.cash-manager table{
    max-width: 100%;
    padding: 10px 20px;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 4px;
    margin: 5px auto;
}
.element-container .title-container{
    width: 100%;
    height: 60px;
    display: grid;
    place-content: center;
}
.element-container form{
    width: 700px;
    padding: 20px 35px;
    margin: 5px auto;
    border-radius: 5px;
    background: #fff;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.21);
    display: flex;
    align-items: start;
    justify-content: center;
    gap:10px 20px;
    flex-wrap: wrap;
}
.element-container form .input-field{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap:2px 30px;
}
.element-container form .input-field1{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap:2px 50px;
}
form .field2{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
}
.element-container form .input-field label,
.element-container form .input-field1 label{
    font-size: 0.9em;
    font-weight: bold;
    width: 20%;
    text-align: end;
}
.element-container form .input-field .field,
.element-container form .input-field1 .field{
    width: 70%;
    margin-top: 5px;
}
.element-container form .input-field input,
.element-container form .input-field select,
.element-container form .input-field1 input,
.element-container form .input-field1 select{
    width: 70%;    
    padding: 8px;
    background: transparent;
    outline: none;
    border: 2px solid #ddd;
    border-radius: 4px;
    transition: all ease-in 0.34s;
}

.element-container form .input-field1 .field button{
    padding: 5px 10px;
    background: blue;
    color:#fff;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    border: 2px solid blue;

}
.element-container form .input-field1 .form-field2{
    width: 70%;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1px 5px;
    background: transparent;
    outline: none;
    border: 2px solid #ddd;
    border-radius: 4px;
    transition: all ease-in 0.34s;
}
.element-container form .form-field2:focus-within{
    border: 2px solid blue;
    background: rgba(0, 0, 255, 0.42);    
}
.element-container form .form-field2:focus-within input,
.element-container form .form-field2:focus-within select{
    color:white;
    font-weight: 520;
}
.element-container form .input-field1 input:focus-within,
.element-container form .input-field1 select:focus-within{
    border: 2px solid blue;
    background: rgba(0, 0, 255, 0.42);  
    color:white;
    font-weight: 520;
}
.element-container form .input-field1 .form-field2 img{
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.element-container form .input-field1 .form-field2 input{
    width: 90%;
    padding: 8px;
    background: transparent;
    margin-top: 0px;
    outline: none;
    border:none;
    transition: all ease-in 0.34s;
}
.element-container form .input-field .error,
.element-container form .error,
.element-container form .input-field1 .error{
    width: 90%;
    font-size: 0.85em;
    font-weight: bold;
    color:red;
    text-align: center;
}
.element-container form .input-field input:focus{
    border:2px solid blue;
    background: rgba(0, 0, 255, 0.42);  
    color:white;
    font-weight: 520;
}
.element-container form .button-field{
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
form .button-field button{
    width: 45%;
    padding: 10px;
    outline: none;
    border:none;
    font-weight: 530;
    cursor: pointer;
    transition: all ease-in 0.35s;
    border-radius: 4px;
}
form .button-field button:active{
    scale: 0.94;
}
form .button-field button[type='submit']{
    background: #222;
    color:#fff;
}
form .button-field button[type='reset']{
    background: #ddd;
    color:#222;
    border: 2px solid #ddd;
}
.element-container table,
table{
    width: 700px;
    padding: 20px 5px;
    border: 1px solid #222;
    border-radius: 5px;
    overflow: hidden;
    border-collapse: collapse;
    background: white;
    margin: 20px auto;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.21);
}
.element-container .filter-container{
    width: 100%;
    padding: 10px 30px;
    display: grid;
    grid-template-columns: 0.9fr 0.9fr 0.9fr 0.5fr;
    gap:10px;
}
.element-container .full-field{
    width: 80%;
    margin: 5px auto;
    background: #fff;
    border-radius: 4px;
    padding: 5px 10px;
}
.element-container .full-field label{
    width: 100%;
    font-size: 14px;
    padding: 5px;
    font-weight: 490;
}
.element-container .full-field input{
    width: 100%;
    margin: 4px 0px;
    padding: 5px;
    outline: none;
    border-radius: 4px;
    border: 2px solid #ddd;
    transition: all ease-in 0.25s;
}
.element-container .full-field:focus-within label{
    color:blue
}
.element-container .full-field:focus-within input{
    background: rgba(0, 0, 255, 0.546);
    color:white;
    font-weight: 490;
    border: 2px solid blue;
}
.filter-container .field{
    width: 100%;
    padding: 8px;
    background: #fff;
    border-radius: 4px;
}
.filter-container .field h2{
    font-size: 12px;
    font-weight: 530;
}
.filter-container .field .filter-field{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:5px;
    margin-top: 5px;
    flex-wrap: wrap;
}
.field .filter-field .row{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.field .filter-field .row label{
    width: 35%;    
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
    font-size: 15px;
    font-weight: bold;
}
.field .filter-field .row p{
    width: 65%;
    font-size: 15px;
}
.field .filter-field .credit-label{
    background: darkgreen;
    color:white;
    padding: 2px 5px;
}
.field .filter-field .credit-value{
    color:darkgreen;
}
.field .filter-field .debit-label{
    background: red;
    color:white;
    padding: 2px 5px;
}
.field .filter-field .debit-value{
    color:red;
}
.field .filter-field .row label img{
    width: 18%;
    height: 20px;
    object-fit: contain;
}
.filter-container .field .filter-field input[type='date']{
    font-size: 12px;
    width: 42%;
    padding: 4px;
    outline: none;
}
.filter-container .field .filter-field select{
    font-size: 12px;
    width: 100%;
    padding: 4px;
    outline: none;
}
.filter-container .field p{
    font-size: 12px;
}
.filter-container button{
    padding: 8px 16px;
    outline: none;
    border: 2px solid #ddd;
    background: #111;
    color:white;
    border-radius: 4px;
    cursor: pointer;
}
table thead th{
    padding: 8px;
    font-size: 0.9em;
    font-weight: 510;
}
table  tr{
    border-bottom: 1px solid #222;
}

table .credit{
    background: red;
    color:whie;
}
table .credit td{
    color:white;
}
table .debit{
    background: purple;
    color:whie;
}
table .debit td{
    color:white;
}
table tbody td{
    padding: 5px;
    text-align: center;
    font-size: 0.85em;
}
table tbody td .eye{
    font-size: 16px;
}
table tbody .action-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    background: #fff;
}
table tbody .action-buttons button{
    outline: none;
    border: none;
    cursor: pointer;    
    padding: 3px 5px;
    font-size: 0.9em;
    border-radius: 3px;
}
table tbody .action-buttons button:first-child{
    background: linear-gradient(to right bottom,limegreen,darkgreen,green);
    color:white;
}
table tbody .action-buttons1{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
table tbody .action-buttons1 button{
    outline: none;
    border: none;
    cursor: pointer;    
    padding: 3px 5px;
    font-size: 0.9em;
    border-radius: 3px;
}
table tbody .action-buttons1 button{
    background: linear-gradient(to right bottom,purple,blueviolet,purple);
    color:white;
}
table tbody .action-buttons .pwd{
    outline: none;
    border: none;
    cursor: pointer;    
    padding: 3px 5px;
    font-size: 0.9em;
    border-radius: 3px;
    background: linear-gradient(to right bottom,purple,blueviolet,purple);
    color:white;
}
table tbody .action-buttons button:last-child{
    background: linear-gradient(to right bottom,crimson,red,crimson);
    color:white;
}

.child-container .pagination{
    width: 700px;
    display: flex;
    align-items: center;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: end;
    gap:8px;
}
.child-container .pagination .data123{
    display: flex;
    align-items: center;
    justify-content: end;
    gap:5px;
}
.child-container .pagination button{
    width: 25px;
    height: 25px;
    display: grid;
    place-content: center;
    transition: all ease-in 0.35s;
    background: #111;
    color:#fff;    
}
.child-container .pagination .data123 button:disabled{
    width: 25px;
    height: 25px;
    display: grid;
    place-content: center;
    transition: all ease-in 0.35s;
    background: #fff;
    cursor: no-drop !important;
    color:#111;    
}
.pagination .data{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:5px;
}
.pagination .data img{
    width: 14px;
    height: 14px;
    object-fit: contain;
}
.pagination .data p{
    font-size: 14px;
}
.pagination .data .title{
    font-weight: bold;
}
 .pagination button{
    padding: 3px 10px;
    outline: none;
    border: 1px solid #222;
    border-radius: 3px;
    cursor: pointer;
    background: #fff;
}
.pagination .selected-page{
    padding: 3px 10px;
    outline: none;
    border: 1px solid #222;
    border-radius: 3px;
    cursor: pointer;
    color:#fff;
    font-weight: 530;
    background: #222;
}
.child-container1{
    width: 100%;
    padding: 10px 3%;
    height: 100%;    
    display: flex;
    align-items: start;
    justify-content: center;
    gap:0.3em;
    background: #dddfff;
}
.child-container1 .panel-show{
    width: 30%;
    margin: 10px auto;
    padding: 15px 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.21);
}
.child-container1 .panel-show .title .title-image{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.child-container .panel-show .title img{
    width: 35px;
    height: 35px;
}
.child-container1 form .title{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;    
}
.child-container1 form .title .title-image{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.child-container form .title img{
    width: 35px;
    height: 35px;
}
.title h2{
    font-size: 1.2em;
}
.title span:first-child{
    font-size: 1.03em;
    color:black !important
}
.title span:last-child{
    margin-left: 0.5px;
    font-size: 1.03em;
    color:black;
    font-weight: bold;
}
.table-container{
    width: 100%;
    padding: 10px 5px;
}
.table-container .record-field{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap:8px;
    margin-bottom: 8px;
}
.table-container .record-field .show-icon{
    width: 40px;
    height: 40px;
}
.table-container .record-field .title-field{    
    width: 30%;
    text-align: center;
    font-size: 1.03em;    
    font-weight: bold;    
}
.table-container .record-field .value-field{
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:5px;
}
.record-field .value-field img{
    width: 8%;
    height: 12px;
}
.record-field .value-field p{
    font-size: 1.02em;
    font-weight: 580;
}
.table-container .line{
    width: 100%;
    height: 2px;
    border: 1px solid #222;
    margin-bottom: 15px;
    background: #111;
}
.table-container a{
    margin: 8px;
    text-decoration: none;
    color:#222;
    transition: all ease-in 0.25;
}
.table-container a:hover{
    color:rgba(0, 0, 255, 0.6);
    text-decoration: underline;
}
.table-container .record-field:last-child{
    margin-bottom: 2px;
}
.table-container .record-field:first-child{
    margin-top: 20px;
}
.red{
    color:red !important;
}
.purple{
    color:purple !important;
}
.green{
    color:darkgreen !important;
}
.input-field1 ul{
    position: absolute;
    width: 100%;
    height: 150px;
    padding: 5px 10px;
    bottom: -152px;
    background: #eee;
    border-radius: 3px;
    list-style: none;
    overflow-y: auto;
    z-index: 10;
}
.input-field1 ul li{
    padding: 5px 8px;
    width: 100%;
    margin-bottom: 2px;
    font-size: 0.86em;
    cursor: pointer;
    border-radius: 3px;
}
.input-field1 ul li:hover{
    background: #ddd;
    font-weight: 520;
}
.bg-blur{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.21);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.bg-blur .box{
    padding: 10px 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.21);
}
.bg-blur .box .title-box{
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.bg-blur .box h2{
    font-size: 18px;
}
.bg-blur .box .message-field{
    width: 350px;    
}
.bg-blur .box .message-field p{
    width: 100%;
    font-weight: 520;
    text-align: center;
}
.bg-blur .box p{
    width: 350px;
    text-align: center;
    margin-bottom: 10px;
}
.bg-blur .box .message-field i{
    font-size: 30px;
}
.bg-blur .box .action-field{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.bg-blur .box .action-field button{
    padding: 6px 20px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 550;
}
.bg-blur .box .action-field button:first-child{
    background: black;
    color:white;
    border: 2px solid black;
}
.bg-blur .box .action-field button:last-child{
    border: 2px solid #ddd;
}
.negative{
    color:red;
}
.opening{
    background: purple;
    color:white;
    padding: 5px;
    border-radius: 2px;
    text-align: center !important;
}
.opening-balance{
    color:purple
}

.debit{
    background: red;
    color:white;
    padding: 5px;
    border-radius: 2px;
    text-align: center !important;
}
.debit-balance{
    color:red;
}
.credit{
    background: darkgreen;
    color:white;
    padding: 5px;
    border-radius: 2px;
    text-align: center !important;
}
.credit-balance{
    color:darkgreen;
}
.page-container{
    position: fixed;
    left: 0;
    top:0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.21);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
.page-container form{
    padding: 20px 30px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.21);
}
.page-container .title-form,
.page-container .input-field{
    width: 350px;
}
.page-container .title-form h2{
    font-size: 20px;    
    font-weight: 540;
}
.page-container .title-form{
    height: 50px;
}
.page-container .input-field{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.page-container .input-field label{
    width: 25%;
    font-size: 16px;
    font-weight: 520;
}
.page-container .input-field input{
    width: 70%;
    outline: none;
    padding: 5px;
    border-radius: 4px;
    border: 2px solid #ddd;
    transition: all ease-in 0.3s;
}
.page-container .input-field input:focus{
    border: 2px solid blue;
    background: rgba(0, 0, 255, 0.473);
    color:#fff
}
.page-container form button{
    width: 100%;
    margin: 5px 0px;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 520;
}
.page-container form .button-field{
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    gap:10px;
}
.page-container form .error-here{
    padding: 8px;
    width: 100%;
    text-align: center;
    color:red;
    font-size: 14px;
    font-weight: 510;
}
.page-container form button[type='submit']{ 
    background: blue;
    color:#fff;   
    border: 2px solid blue;    
}
.page-container form button[type='reset']{
    margin-top: 5px;    
    background: crimson;
    color:#fff;   
    border: 2px solid crimson;    
}
.agent-container{
    width: 100%;
    height: 100vh;
    background: whitesmoke;
}
.agent-container .agent-header{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.21);
    padding: 0px 5%;
}
.agent-header .value1{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap:6px;
}
.agent-header .value1 p{
    font-size: 14px;
    font-weight: 540;
}
.agent-header .value2{
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    gap:6px;
}
.agent-header .value2 .filter-date{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:4px;
}
.agent-header .value2 h3{
    font-size: 15px;
    font-weight: 540;
}
.agent-header .value2 .filter-date input{
    padding: 3px;
    font-size: 12px;
    outline: none;
    border-radius: 4px;
    border: 1px solid black;
}
.agent-header .value2 .filter-date p{
    font-size: 13px;
    margin: 0px 5px;
    font-weight: 500;
}
.confirmation-box{
    position: absolute;
    top:0;
    left: 0;
    z-index: 300;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.31);
    display: flex;
    align-items: center;
    justify-content: center;
}
.confirmation-box .question{
    width: 350px;
    padding: 0px;
    border-radius: 5px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.21);
    overflow: hidden;
}
.confirmation-box .question .title{
    width: 100%;
    height: 40px;
    background: #dededd;
    display: grid;
    place-content: center;
}
.confirmation-box .question .title h2{
    font-size: 15px;
    font-weight: 500;
}
.confirmation-box .question-data{
    padding: 10px 15px;
}
.confirmation-box .question-data p{
    font-size: 15px;
    text-align: center;
}
.confirmation-box .footer{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.confirmation-box .footer button{
    padding: 8px 25px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 500;
    transition: all ease-in 0.35s;
}
.confirmation-box .footer button:first-child{
    background: whitesmoke;
    color:#111;
    border: 2px solid #ddd;
}
.confirmation-box .footer button:last-child{
    background: transparent;
    color:#111;
    border: 2px solid #ddd;
}
.page-container1{
    width: 100%;
    height: 100%;   
    overflow-y: auto; 
}
.page-container1 .title{
    padding: 10px 2%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:10px;
    background: #fff;
}
.page-container1 .title i{
    font-size: 25px;
    color:crimson;
}
.page-container1 .title h2{
    font-size: 22px;
    font-weight: 510;
    color:crimson;
}
.page-container1 .data-container{
    width: 100%;
    padding: 10px 2%;
    display: flex;
    align-items: start;
    justify-content: start;
    gap:10px 20px;
    
}
.data-container .account-statement,
.data-container .record-statement{
    width: 50%;
    padding: 2px 15px;
    height: 460px;
    background: #fff;
    border-radius: 3px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.21);
}
.data-container .account-statement .title-field,
.data-container .cash-in-hand .title-field{
    width: 100%;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.data-container .account-statement .title-field h2,
.data-container .cash-in-hand .title-field h2{
    font-size: 16px;
    font-weight: 510;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
}
.data-container .account-statement .title-field h2 i,
.data-container .cash-in-hand .title-field h2 i{
    font-size: 20px;
}
.data-container .account-statement .filter{
    display: flex;
    align-items: center;
    justify-content: start;
    gap:8px;
    padding: 5px 10px;
}
.data-container .account-statement .filter p{
    font-size: 13px;
    font-weight: 400;
    border-radius: 3px;
    padding: 4px 8px;  
    cursor: pointer;  
    transition: all ease-in 0.25s;
}
.data-container .account-statement .filter p:hover{
    background: #eee;
}
.data-container .account-statement .filter .selected-filter{
    background: #ddd;
    border-radius: 3px;
    font-weight: 500;
}
.data-container .account-statement .table-data{
    width: 100%;
    margin: 5px 0px;
    box-shadow: none;
    border: none;
    border-collapse: collapse;
    overflow: auto;
}
.data-container .account-statement .table-data thead{
    background: #ddd;
}
.data-container .account-statement .table-data tr,
.data-container .account-statement .table-data td,
.data-container .account-statement .table-data th{
    border: 1px solid black;
}
.data-container .account-statement .table-data tr{
    cursor: pointer;
    transition: all ease-in 0.25s;
}
.data-container .account-statement .table-data tr:hover{
    background: #eee;
}
.data-container .account-statement .table-data th{
    font-size: 14px !important;
    font-weight: 500;
    border: 1px solid black;
    text-transform: capitalize;
}
.data-container .account-statement .table-data td i{
    margin-left: 10px;
}
.data-container .account-statement .table-data thead{
    border: 1px solid black     ;
}
.data-container .account-statement .pagination{
    margin: 4px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:6px;
}
.data-container .account-statement .pagination p{
    font-size: 13px;
}
.data-container .account-statement .pagination .data123{
    display: flex;
    align-items: center;
    justify-content: end;
    gap:5px;
}
.data-container .account-statement .pagination button{
    width: 25px;
    height: 25px;
    display: grid;
    place-content: center;
    transition: all ease-in 0.35s;
    background: #111;
    color:#fff;    
}
.data-container .account-statement .pagination .data123 button:disabled{
    width: 25px;
    height: 25px;
    display: grid;
    place-content: center;
    transition: all ease-in 0.35s;
    background: #fff;
    cursor: no-drop !important;
    color:#111;    
}
.account-container{
    width: 100%;
    height: 100%;
}
.account-container .filter-container{
    padding: 10px 0px;
    margin:10px auto;
    width: 80%;
}
.account-container .filter-container label{
    font-size: 0.92rem;
    font-weight: 480;
}
.account-container .filter-container input{
    width: 100%;
    padding: 6px;
    outline: none;
    border: 2px solid #ddd;
    border-radius: 3px;
    margin-top: 5px;
}
.account-container .title-container{
    width: 100%;
    height: 80px;
    background: lightblue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 3%;
}
.account-container .title-container h2{
    font-size: 1.01rem;
    font-weight: 510;
}
.account-container .title-container .balance{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap:10px;
}
.account-container .title-container .balance .field{
    width: 350px;
    display: flex;
    align-items: start;
    justify-content: start;
    gap:8px;
}
.title-container .balance .field label{
    width: 60%;
    text-align: end;
    font-size: 0.92rem;
    font-weight: bold;
}  
.title-container .balance .field p{
    text-align: end;
    font-size: 0.92rem;
    font-weight: 500;
}